import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'welcome',
    pathMatch: 'full'
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module')
      .then(m => m.HomePageModule)
  },
  {
    path: 'welcome',
    loadChildren: () => import('./pages/welcome/welcome.module')
      .then(m => m.WelcomePageModule)
  },
  {
    path: 'vendor-profile',
    loadChildren: () => import('./pages/vendor-profile/vendor-profile.module')
      .then(m => m.VendorProfilePageModule)
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./pages/dashboard/dashboard.module')
      .then(m => m.DashboardPageModule)
  },
  {
    path: 'update-profile',
    loadChildren: () => import('./pages/update-profile/update-profile.module')
      .then(m => m.UpdateProfilePageModule)
  },
  {
    path: 'vendor-users',
    loadChildren: () => import('./pages/vendor-users/vendor-users.module')
      .then(m => m.VendorUsersPageModule)
  },
  {
    path: 'add-user',
    loadChildren: () => import('./pages/add-user/add-user.module')
      .then(m => m.AddUserPageModule)
  },
  {
    path: 'edit-user/:uid',
    loadChildren: () => import('./pages/add-user/add-user.module')
      .then(m => m.AddUserPageModule)
  },
  {
    path: 'phone-contact',
    loadChildren: () => import('./pages/phone-contact/phone-contact.module')
      .then(m => m.PhoneContactPageModule)
  },
  {
    path: 'vendor-clients',
    loadChildren: () => import('./pages/vendor-clients/vendor-clients.module')
      .then(m => m.VendorClientsPageModule)
  },
  {
    path: 'create-client',
    loadChildren: () => import('./pages/vendor-create-client/vendor-create-client.module')
      .then(m => m.VendorCreateClientPageModule)
  },
  {
    path: 'edit-client/:cid/:cname',
    loadChildren: () => import('./pages/vendor-create-client/vendor-create-client.module')
      .then(m => m.VendorCreateClientPageModule)
  },
  {
    path: 'my-contact',
    loadChildren: () => import('./pages/my-contact/my-contact.module')
      .then(m => m.MyContactPageModule)
  },
  {
    path: 'vendor-ledger',
    loadChildren: () => import('./pages/vendor-ledger/vendor-ledger.module')
      .then(m => m.VendorLedgerPageModule)
  },
  {
    path: 'client-transaction/:cid',
    loadChildren: () => import('./pages/client-transaction/client-transaction.module')
      .then(m => m.ClientTransactionPageModule)
  },
  {
    path: 'reason',
    loadChildren: () => import('./pages/reason/reason.module')
      .then(m => m.ReasonPageModule)
  },
  {
    path: 'vendor-reasons',
    loadChildren: () => import('./pages/vendor-reasons/vendor-reasons.module')
      .then(m => m.VendorReasonsPageModule)
  },
  {
    path: 'vendor-client-bill/:cid',
    loadChildren: () => import('./pages/vendor-client-bill/vendor-client-bill.module')
      .then(m => m.VendorClientBillPageModule)
  },
  {
    path: 'vendor-client-bill/:cid/:ledgerid',
    loadChildren: () => import('./pages/vendor-client-bill/vendor-client-bill.module')
      .then(m => m.VendorClientBillPageModule)
  },
  {
    path: 'vendor-client-payment/:cid',
    loadChildren: () => import('./pages/vendor-client-payment/vendor-client-payment.module')
      .then(m => m.VendorClientPaymentPageModule)
  },
  {
    path: 'vendor-client-payment/:cid/:ledgerid',
    loadChildren: () => import('./pages/vendor-client-payment/vendor-client-payment.module')
      .then(m => m.VendorClientPaymentPageModule)
  },
  {
    path: 'client-addresses/:cid/:cname',
    loadChildren: () => import('./pages/client-addresses/client-addresses.module')
      .then(m => m.ClientAddressesPageModule)
  },
  {
    path: 'client-accounts/:cid/:cname',
    loadChildren: () => import('./pages/client-accounts/client-accounts.module')
      .then(m => m.ClientAccountsPageModule)
  },
  {
    path: 'add-account/:cid/:cname',
    loadChildren: () => import('./pages/add-account/add-account.module')
      .then(m => m.AddAccountPageModule)
  },
  {
    path: 'edit-account/:cid/:aid/:cname',
    loadChildren: () => import('./pages/add-account/add-account.module')
      .then(m => m.AddAccountPageModule)
  },
  {
    path: 'add-address/:cid/:cname',
    loadChildren: () => import('./pages/add-address/add-address.module')
      .then(m => m.AddAddressPageModule)
  },
  {
    path: 'edit-address/:cid/:aid/:cname',
    loadChildren: () => import('./pages/add-address/add-address.module')
      .then(m => m.AddAddressPageModule)
  },
  {
    path: 'collection-report/:vendorid',
    loadChildren: () => import('./pages/collection-report/collection-report.module')
      .then(m => m.CollectionReportPageModule)
  },
  {
    path: 'report-page',
    loadChildren: () => import('./pages/report-page/report-page.module')
      .then(m => m.ReportPagePageModule)
  },
  {
    path: 'building-allocation',
    loadChildren: () => import('./pages/building-allocation/building-allocation.module')
      .then(m => m.BuildingAllocationPageModule)
  },
  {
    path: 'allocated-building',
    loadChildren: () => import('./pages/allocated-building/allocated-building.module')
      .then(m => m.AllocatedBuildingPageModule)
  },
  {
    path: 'report/:type/:vendorid',
    loadChildren: () => import('./pages/report-page/report-page.module')
      .then(m => m.ReportPagePageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
